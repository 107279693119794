import { lazy } from '../../shared/deployment';

export const NetworkingWidgetInit = lazy(() => import('./ui/Init'));
export const NetworkingWidgetShow = lazy(() => import('./ui/Show'));
export const NetworkingWidgetUserList = lazy(() => import('./ui/UserList'));
export const NetworkingWidgetSettings = lazy(() => import('./ui/Settings'));
export const NetworkingWidgetNotificationCount = lazy(() => import('./ui/NotificationCount'));

export {
  SET_NETWORKING_WIDGET, setLoading, setReady, setError, selectStatus
} from './model/slice';
