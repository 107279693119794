import { lazy } from '../../shared/deployment';

export const AccessControlInit = lazy(() => import('./ui/AccessControlInit'));

/* for some reason, loading AccessControlWrapper as lazy gives this error:
 "Error: A component suspended while responding to synchronous input.
 This will cause the UI to be replaced with a loading indicator.
 To fix, updates that suspend should be wrapped with startTransition." */
export { default as AccessControlWrapper } from './ui/AccessControlWrapper';

export { useIsActive as useIsAccessControlActive } from './model/hooks';

export { SET_ACCESS_CONTROL } from './model/slice';
export { MODULE_NAME } from './model/constants';

export * as vmFunctions from './model/vm-functions';
