/*
This is a singleton-accessed react lazy with reload.

It holds a state of the version of the deployment. This version is
set at this module load time.

Module export enhanced react's lazy function, that retries
to fetch chunk if previous and current version disagree.
*/
import { lazy } from 'react';
import { errorFactory } from '../logger';

// Module config
const FILENAME = './static/version.json';

// Module state
let versionPrev = null;

// Module methods
const fetchVersionDev = async () => ({ Version: 'Dev env' });
const fetchVersionProd = () => fetch(FILENAME)
  .then((resp) => resp.json())
  .then((o) => o.Version)
  .catch((error) => {
    throw errorFactory('Cannot access version information', { cause: error });
  });

const fetchVersion = process.env.NODE_ENV === 'development' ? fetchVersionDev : fetchVersionProd;

const reload = (error) => fetchVersion().then((versionCurr) => {
  if (versionPrev !== versionCurr) window.location.reload();
  else throw errorFactory('Chunk reload failed.', { cause: { versionCurr, versionPrev, error } });
});

const reportVersion = async () => {
  if (process.env.NODE_ENV === 'development') console.info(`Deployment version: ${versionPrev}`);
};

const setVersion = async (ver) => {
  versionPrev = ver;
};

const initVersion = async () => {
  if (versionPrev) throw errorFactory('Cannot set version. Version already set', { cause: versionPrev });

  return fetchVersion().then(setVersion).then(reportVersion);
};

// Module run
// Execute once: set version
initVersion();

export default function lazyWithReload(importFn) {
  return lazy(() => importFn().catch(reload));
}
