import { lazy } from '../../shared/deployment';

export const JmeText = lazy(() => import('./JmeText'));
export const JmeInputBase = lazy(() => import('./JmeInputBase'));
export const DynamicContent = lazy(() => import('./DynamicContent'));
export const Floorplan = lazy(() => import('./Floorplan/Floorplan'));
export const HtmlContent = lazy(() => import('./HtmlContent'));
export const TextHref = lazy(() => import('./TextHref'));
export const JmeAvatar = lazy(() => import('./JmeAvatar'));
export const JmeAvatar2 = lazy(() => import('./JmeAvatar2'));
export const JmeLabel = lazy(() => import('./JmeLabel'));
export const JmeRatingStars = lazy(() => import('./JmeRatingStars'));
export const AddLinksToText = lazy(() => import('./AddLinksToText'));
