// A wrapper around errors thrown by us.
const TOKEN = 'vm2';

class Vm2Error extends Error {
  constructor(message, options) {
    super(`[${TOKEN}] ${message}`, options); // Add token to the message
    this.name = 'Vm2Error';
    this.token = TOKEN;
  }
}

export const errorFactory = (message, options) => new Vm2Error(message, options);
