import { lazy } from '../../shared/deployment';

// Collapse
export const JmeCollapse = lazy(() => import('./JmeCollapse'));

export const JmeAccordionGroup = lazy(() => import('./JmeAccordionGroup'));
export const JmeAccordionItem = lazy(() => import('./JmeAccordionItem'));

export const JmeShowAllCollapse = lazy(() => import('./JmeShowAllCollapse'));
export const VmAccordion = lazy(() => import('./VmAccordion'));
